import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ConfirmationService } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { lastValueFrom } from 'rxjs';
import { NotificationService } from '../../../../shared/services/notification.service';
import { UserDto } from '../../shared/api/user-details.model';
import { UserProjectMappingDto, UserProjectMappingStatusEnum } from '../api/user-project-mapping.model';
import { ConfigurationService } from '../../shared/api/configuration.service';
import { UserDetailsService } from '../../shared/api/user-details.service';
import { UserProjectMappingService } from '../api/user-project-mapping.service';
import { ProjectUserEditComponent } from './project-user-edit.component';

@Component({
  templateUrl: './project-users.component.html',
  styles: ['.p-autocomplete-panel {z-index: 1003!important;} :host ::ng-deep .p-tabview .p-tabview-panels {padding:0} .p-dialog .p-dialog-content {padding:0 !important}'],
  providers: [ConfigurationService, ConfirmationService]
})
export class ProjectUsersComponent implements OnInit {

  projectId!: string;
  addPanel: boolean = false;
  addPanelIsOwner: boolean = false;

  users: UserDto[] = [];
  usersAutocomplete: UserDto[] = [];
  projectUsers: UserProjectMappingDto[] = [];
  userToAdd?: UserDto = undefined;

  statusEnum = UserProjectMappingStatusEnum;

  form!: FormGroup;

  projectUserDialog!: DynamicDialogRef;

  constructor(
    private dialogService: DialogService,
    private userProjectMappingService: UserProjectMappingService,
    private userService: UserDetailsService,
    private confirmationService: ConfirmationService,
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private notify: NotificationService
  ) {
    this.projectId = this.config.data.id;
  }

  async ngOnInit() {
    this.refreshProjectUsers();
  }

  async refreshProjectUsers() {
    this.userProjectMappingService.projectUsers(this.projectId)
      .subscribe((response: UserProjectMappingDto[]) => this.projectUsers = response);
  }

  deleteProjectUser(userId: string) {
    this.confirmationService.confirm({
      //target: event.target as EventTarget,
      message: 'Do you want to delete this project user?',
      header: 'Delete Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptButtonStyleClass: "p-button-danger p-button-text",
      rejectButtonStyleClass: "p-button-text p-button-text",
      acceptIcon: "none",
      rejectIcon: "none",

      accept: () => {
        this.userProjectMappingService.projectUserDelete(this.projectId, userId)
          .subscribe(() => this.refreshProjectUsers());
      },
      reject: () => {
      }
    });
  }

  cancel() {
    this.ref.close();
  }

  async userAutocomplete(event: any) {
    const q = event.query;
    this.usersAutocomplete = this.users.filter(p => {
      return p.email.toLowerCase().indexOf(q) >= 0;
    })

    this.usersAutocomplete = await lastValueFrom(this.userService.userSearch(q));
  }

  async userSelected(event: any) {
    let user = event.value as UserDto;

    this.userProjectMappingService
      .projectUserCreate(this.projectId, new UserProjectMappingDto({ projectId: this.projectId, userId: user.userId }))
      .subscribe(() => {
        this.userToAdd = undefined;
        this.addPanel = false;
        this.refreshProjectUsers()
      });
  }

  openProjectUserDialog(userId?: string) {
    this.projectUserDialog = this.dialogService
      .open(ProjectUserEditComponent, {
        data: { projectId: this.projectId, userId: userId },
        header: 'User Project Mapping',
        width: '800px'
      });

    this.projectUserDialog.onClose
      .subscribe(() => {
        this.refreshProjectUsers();
      });
  }
}
