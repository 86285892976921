
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { lastValueFrom } from 'rxjs';
import { formAddValidators } from '../../../../shared/utils';
import { ValidationRule } from '../../../../shared/models/validation-rule';
import { UserDto } from '../../shared/api/user-details.model';
import { UserProjectMappingDto, UserProjectMappingStatusEnum } from '../api/user-project-mapping.model';
import { UserDetailsService } from '../../shared/api/user-details.service';
import { ProjectProfileService } from '../api/project-profile.service';
import { UserProjectMappingService } from '../api/user-project-mapping.service';
import { ConfigurationService } from '../../shared/api/configuration.service';
import { NotificationService } from '../../../../shared/services/notification.service';

@Component({
  templateUrl: './project-user-edit.component.html',
  styles: ['.p-autocomplete-panel {z-index: 1003!important;}']
})
export class ProjectUserEditComponent implements OnInit {

  projectId!: string;
  userId!: string;

  roles: string[] = [];
  projects: string[] = [];

  validators: ValidationRule[] = [];
  form?: FormGroup = undefined;
  formBlocked = false;

  usersAutocomplete: UserDto[] = [];

  statuses = [UserProjectMappingStatusEnum.Pending, UserProjectMappingStatusEnum.Approved, UserProjectMappingStatusEnum.Rejected];

  constructor(
    private fb: FormBuilder,
    private userService: UserDetailsService,
    private projectService: ProjectProfileService,
    private userProjectMappingService: UserProjectMappingService,
    private sharedService: ConfigurationService,
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private notify: NotificationService
  ) {
    this.projectId = this.config.data.projectId;
    this.userId = this.config.data.userId;
  }

  async ngOnInit() {

    this.roles = await lastValueFrom(this.sharedService.roles());
    this.projects = (await lastValueFrom(this.projectService.projectProfiles()))
      .map(q => q.projectId);

    let mapping = new UserProjectMappingDto({ projectId: this.projectId, userId: this.userId });

    if (this.userId) {
      this.validators = await lastValueFrom(this.userProjectMappingService.getValidators('UserProjectMappingUpdate'));
      mapping = await lastValueFrom(this.userProjectMappingService.projectUserGet(this.projectId, this.userId));
    } else {
      this.validators = await lastValueFrom(this.userProjectMappingService.getValidators('UserProjectMappingCreate'));
    }

    this.form = formAddValidators(this.fb.group(mapping), this.validators);
  }
  
  cancel() {
    this.ref.close();
  }

  save() {
    this.formBlocked = true;
    if (!this.userId) {
      this.userProjectMappingService.
        projectUserCreate(this.projectId ?? this.form?.value.projectId ?? "", this.form?.value)
        .subscribe({
          next: r => {
            if (r) {
              this.notify.success('Saved')
              this.ref.close(r);
            } else {
              this.notify.error('Failed', r);
            }
            this.formBlocked = false;
          },
          error: () => this.formBlocked = false
        });
    } else {
      this.userProjectMappingService.
        projectUserUpdate(this.projectId ?? this.form?.value.projectId ?? "", this.userId, this.form?.value)
        .subscribe({
          next: r => {
            if (r) {
              this.notify.success('Saved')
              this.ref.close(r);
            } else {
              this.notify.error('Failed', r);
            }
            this.formBlocked = false
          },
          error: () => this.formBlocked = false
        })
    }
  }

  async userAutocomplete(event: any) {
    const q = event.query;
    this.usersAutocomplete = await lastValueFrom(this.userService
      .userSearch(q));
    if (this.usersAutocomplete.length == 1) {
      this.userSelected({ value: this.usersAutocomplete[0] });
    }
  }


  userSelected(event: any) {
    const user = event.value as UserDto;
    this.form?.patchValue(user);
  }
}

