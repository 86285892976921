import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ProjectEditComponent } from './components/project-edit.component';
import { ProjectListComponent } from './components/project-list.component';
import { ProjectUserListComponent } from './components/project-users-list.component';

const routes: Routes = [
  { path: 'list', pathMatch: 'full', /*canActivate: [MsalGuard],*/ component: ProjectListComponent },
  { path: 'users', pathMatch: 'full',/*canActivate: [MsalGuard],*/ component: ProjectUserListComponent },
  { path: 'edit/:id', pathMatch: 'full',/*canActivate: [MsalGuard],*/ component: ProjectEditComponent },
  { path: 'edit', pathMatch: 'full',/*canActivate: [MsalGuard],*/ component: ProjectEditComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProjectsRoutingModule { }
