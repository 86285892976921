
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ConfirmationService } from 'primeng/api';
import { DropdownChangeEvent } from 'primeng/dropdown';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { lastValueFrom } from 'rxjs';
import { formAddValidators } from '../../../../shared/utils';
import { ConfigurationService } from '../../shared/api/configuration.service';
import { TenantDto } from '../../shared/api/configuration.model';
import { ValidationRule } from '../../../../shared/models/validation-rule';
import { ProjectProfileService } from '../api/project-profile.service';
import { NotificationService } from '../../../../shared/services/notification.service';
import { ProjectProfileDto } from '../api/project-profile.model';

@Component({
  templateUrl: './project-edit.component.html',
  styles: ['.p-autocomplete-panel {z-index: 1003!important;} :host ::ng-deep .p-tabview .p-tabview-panels {padding:0} .p-dialog .p-dialog-content {padding:0 !important}'],
  providers: [ConfigurationService, ConfirmationService]
})
export class ProjectEditComponent implements OnInit {

  id!: string;
  addPanel: boolean = false;
  addPanelIsOwner: boolean = false;
  tenants: TenantDto[] = [];

  validators: ValidationRule[] = [];
  form?: FormGroup = undefined;
  formBlocked = false;

  constructor(
    private fb: FormBuilder,
    private projectService: ProjectProfileService,
    private configurationService: ConfigurationService,
    private confirmationService: ConfirmationService,
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private notify: NotificationService
  ) {
    this.id = this.config.data.id;
  }

  async ngOnInit() {
    let project = new ProjectProfileDto();
    if (this.id) {
      this.validators = await lastValueFrom(this.projectService.getValidators('ProjectUpdate'));
      project = await lastValueFrom(this.projectService.projectProfileGet(this.id));
    } else {
      this.validators = await lastValueFrom(this.projectService.getValidators('ProjectCreate'));
    }

    this.form = formAddValidators(this.fb.group(project), this.validators);

    this.configurationService.tenants()
      .subscribe(q => this.tenants = q);
  }
  
  tenantChanged(event: DropdownChangeEvent) {
    const tenant = this.tenants.find(t => t.tenantId == event.value);
    this.form?.patchValue({ tenantName: tenant?.tenantName });
  }

  cancel() {
    this.ref.close();
  }

  save() {
    this.formBlocked = true;
    if (!this.id) {
      this.projectService.
        projectProfileCreate(this.form?.value)
        .subscribe({
          next: r => {
            if (r) {
              this.notify.success('Saved')
              this.ref.close(r);
            } else {
              this.notify.error('Failed', r);
            }
            this.formBlocked = false;
          },
          error: e => this.formBlocked = false
        });
    } else {
      this.projectService.
        projectProfileUpdate(this.id, this.form?.value)
        .subscribe({
          next: r => {
            if (r) {
              this.notify.success('Saved')
              this.ref.close(r);
            } else {
              this.notify.error('Failed', r);
            }
            this.formBlocked = false
          },
          error: e => this.formBlocked = false
        });
    }
  }
}

