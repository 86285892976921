@if(form){
<form #formProject [formGroup]="form" class="mt-4">
  <div>
    <div class="field">
      <label for="projectId" class="block text-900 font-medium mb-2">Project Id *</label>
      <input id="projectId" formControlName="projectId" type="text" pInputText placeholder="Project Id" class="w-full mb-3">
    </div>
    <div class="field">
      <label for="name" class="block text-900 font-medium mb-2">Project Name *</label>
      <input id="name" formControlName="projectName" type="text" pInputText placeholder="Project Name" class="w-full mb-3">
    </div>
    <div class="field">
      <label for="tenant" class="block text-900 font-medium mb-2">Project Tenant *</label>
      <p-dropdown [options]="tenants" formControlName="tenantId" placeholder="Tenant" optionLabel="tenantName" optionValue="tenantId"
                  [editable]="false" appendTo="body" styleClass="w-full mb-3" inputStyleClass="w-full" (onChange)="tenantChanged($event)"></p-dropdown>
    </div>

    <div class="text-center">
      <p-button label="Cancel" icon="pi pi-times" class="m-3" (click)="cancel()"></p-button>
      <p-button label="Save" icon="pi pi-check" class="m-3" [disabled]="!form.valid" (onClick)="save()"></p-button>
    </div>
  </div>
</form>
<p-blockUI [target]="formProject" [blocked]="formBlocked">
  <i class="pi pi-cloud-upload" style="font-size: 3rem"></i>
</p-blockUI>
}
@else{
<div class="flex justify-content-center">
  <p-progressSpinner></p-progressSpinner>
</div>
}

<!--projectId: string = uuidv4(); // This will be a GUID
userId: string = uuidv4(); // This will be a GUID
status: UserApprovalEnum = UserApprovalEnum.None;
opsApproved: UserApprovalEnum = UserApprovalEnum.None;
ownerApproved: UserApprovalEnum = UserApprovalEnum.None;
creationDateTime: Date = new Date();
mappedBy: string = uuidv4(); // This will be a GUID
isOwner: boolean = false;-->
