import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { LayoutService } from '../../../../shared/services/layout.service';
import { ProjectProfileDto } from '../api/project-profile.model';
import { ConfigurationService } from '../../shared/api/configuration.service';
import { ProjectProfileService } from '../api/project-profile.service';
import { ProjectEditComponent } from './project-edit.component';
import { ProjectUsersComponent } from './project-users.component';


@Component({
  templateUrl: './project-list.component.html',
  providers: [DialogService, ConfigurationService, ConfirmationService],
  styles: ['']
})
export class ProjectListComponent implements OnInit {

  projects: ProjectProfileDto[] = [];
  projectsFiltered: ProjectProfileDto[] = [];

  filterTenants: string[] = [];

  filters: ProjectListFilter = {};

  projectDialog!: DynamicDialogRef;
  usersDialog!: DynamicDialogRef;

  constructor(
    private dialogService: DialogService,
    private projectService: ProjectProfileService,
    private confirmationService: ConfirmationService,
    public router: Router,
    private layout: LayoutService
  ) {
      
  }

  async ngOnInit() {
    await this.refreshList();
  }

  async refreshFilterLists(list: ProjectProfileDto[]) {
    this.filterTenants = [...new Set(list.map(q => q.tenantName).filter(q => q))];
  }

  filterList() {
    this.projectsFiltered = this.projects
      .filter(p =>
      (!this.filters.projectId || p.projectId.toLowerCase().includes(this.filters.projectId.toLowerCase()) || p.projectName.toLowerCase().includes(this.filters.projectId.toLowerCase()))
        && (!this.filters.tenant || p.tenantName === this.filters.tenant)
      );
  }

  filtersClear() {
    this.filters.projectId = undefined;
    this.filters.tenant = undefined;

    this.refreshList();
  }

  async refreshList() {
    this.layout.startLoading();
    this.projectService.projectProfiles()
      .subscribe({
        next: (response: ProjectProfileDto[]) => {
          this.refreshFilterLists(response);
          this.projects = response;
          this.filterList();
          this.layout.stopLoading();
        },
        error: (error) => {
          this.layout.stopLoading();
        }
      });
  }

  openProjectDialog(id?:string) {
    this.projectDialog = this.dialogService
      .open(ProjectEditComponent, {
        data: { id: id },
        header: 'Project Profile',
        width: '800px'
      });

    this.projectDialog.onClose
      .subscribe(() => {
          this.refreshList();
      });
  }

  openUsersDialog(id: string, name?: string) {
    this.usersDialog = this.dialogService
      .open(ProjectUsersComponent, {
        data: { id: id },
        header: `User Project Mappings of '${name}'`,
        width: '800px'
      });

    this.usersDialog.onClose
      .subscribe(() => {
        this.refreshList();
      });
  }

  deleteProject(id: string) {

    this.confirmationService.confirm({
      //target: event.target as EventTarget,
      message: 'Do you want to delete this project?',
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      acceptButtonStyleClass: "p-button-danger p-button-text",
      rejectButtonStyleClass: "p-button-text p-button-text",
      acceptIcon: "none",
      rejectIcon: "none",

      accept: () => {
        this.projectService
          .projectProfileDelete(id)
          .subscribe(q => this.refreshList());
      },
      reject: () => {
      }
    });
  }
}

class ProjectListFilter {
  projectId?: string;
  tenant?: string;
}
