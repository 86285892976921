
<div class="flex mt-2 mb-2 justify-content-between">
  <span class="text-4xl">User Project Mappings <sup class="p-badge p-badge-info">{{projectUsersFiltered.length}}</sup></span>
  <p-button label="Create" icon="pi pi-plus" styleClass="p-button-link" (click)="openProjectUserDialog()"></p-button>
</div>

<div class="flex mt-2 mb-2 justify-content-around">
  <div class="flex-1 mr-2">
    <p-dropdown [options]="filterProjects" placeholder="Project" [showClear]="true" [filter]="true" [editable]="false"
                [(ngModel)]="filters.projectId"
                (onChange)="refreshList()"
                (onClear)="filters.projectId = undefined; refreshList();"
                appendTo="body" styleClass="w-full" inputStyleClass="w-full"></p-dropdown>
  </div>
  <div class="flex-1 mr-2">
    <p-dropdown [options]="filterEmails" [showClear]="true" [filter]="true" placeholder="User Email"
                [(ngModel)]="filters.email"
                (onChange)="refreshList()"
                (onClear)="filters.email = undefined; refreshList()"
                appendTo="body" styleClass="w-full" inputStyleClass="w-full" class="w-full" ></p-dropdown>
  </div>
  <div class="flex-1 mr-2">
    <p-dropdown [options]="filterStatuses" placeholder="Status" [showClear]="true"
                [(ngModel)]="filters.status"
                (onChange)="refreshList()"
                (onClear)="filters.status = undefined; refreshList()" 
                [editable]="false" appendTo="body" styleClass="w-full" inputStyleClass="w-full"></p-dropdown>
  </div>
  <div class="">
    <i pButton [rounded]="true" [text]="true" size="small" severity="secondary" icon="pi pi-times" (click)="filtersClear()"></i>
  </div>
</div>

<p-table [value]="projectUsersFiltered" styleClass="p-datatable-sm w-full"
         [rowHover]="true" sortField="name">
  <ng-template pTemplate="header">
    <tr>
      <th pSortableColumn="projectId">Project</th>
      <th pSortableColumn="email">Email</th>
      <th pSortableColumn="role">Role</th>
      <th pSortableColumn="creationDateTime">Creation Date</th>
      <th pSortableColumn="isActive">Active</th>
      <th pSortableColumn="status">Status</th>
      <th class="text-right"></th> 
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-item let-i="rowIndex">
    <tr title="{{item.userId}}" (dblclick)="openProjectUserDialog(item.projectId, item.userId)" class="cursor-pointer">
      <td>{{item.projectId}}</td>
      <td>{{item.email}}</td>
      <td>{{item.role}}</td>
      <td>{{item.creationDateTime|date:'mediumDate'}}</td>
      <td>
        <i class="pi" [ngClass]="{'pi-check-circle':item.isActive, 'pi-times-circle':!item.isActive }"></i>
      </td>
      <td>
        <i class="pi" [ngClass]="{'pi-question-circle':item.status == statusEnum.Pending, 'pi-check-circle':item.status == statusEnum.Approved, 'pi-times-circle':item.status == statusEnum.Rejected }"></i>
      </td>
      <td class="text-right">
        <p-button icon="pi pi-pencil" styleClass="p-button-link" (click)="openProjectUserDialog(item.projectId, item.userId)"></p-button>
        <p-button icon="pi pi-times" styleClass="p-button-link" (click)="deleteProjectUser(item.projectId, item.userId)"></p-button>
      </td>
    </tr>
  </ng-template>
</p-table>
<p-confirmDialog></p-confirmDialog>

<!--export class UserProjectMappingDto {
  projectId: string = '';
  userId: string = uuidv4(); // This will be a GUID
  email: string = '';
  meshUsername: string = '';
  role: string = '';
  isActive: boolean = true;
  isApproved?: boolean;
  creationDateTime: Date = new Date();

  constructor(partial?: Partial<UserProjectMappingDto>
  ) {
  Object.assign(this, partial);
  }
  }-->
