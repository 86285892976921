    @if(addPanel){
<div class="flex m-2">
  <!--  <div class="flex-1 flex align-items-center justify-content-center">
      <p-checkbox [(ngModel)]="addPanelIsOwner" [binary]="true" inputId="owner"></p-checkbox>
      <label class="ml-2" for="owner">Owner</label>
    </div>-->
  <div class="flex-auto flex align-items-center justify-content-center">
    <p-autoComplete styleClass="w-full" inputStyleClass="w-full" class="w-full"
                    [(ngModel)]="userToAdd" [suggestions]="usersAutocomplete" appendTo="body" field="email" [minLength]=1 dataKey="userId"
                    (completeMethod)="userAutocomplete($event)" (onSelect)="userSelected($event)"></p-autoComplete>
    <!--(onBlur)="batchBlur($event, item)"-->
  </div>
  <div class="flex-1 flex align-items-center justify-content-center">
    <i pButton [rounded]="true" [text]="true" size="small" severity="secondary" icon="pi pi-times" (click)="addPanel=false"></i>
  </div>
</div>
    }

<p-table [value]="projectUsers" styleClass="p-datatable-sm w-full"
         [rowHover]="true" sortField="name">
  <ng-template pTemplate="header">
    <tr>
      <th pSortableColumn="email">Email</th>
      <th pSortableColumn="role">Role</th>
      <th pSortableColumn="isActive">Active</th>
      <th pSortableColumn="status">Status</th>
      <th class="text-right"><i pButton [rounded]="true" size="small" [text]="true" icon="pi pi-plus" (click)="openProjectUserDialog()"></i></th> <!--addPanel=true-->
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-item let-i="rowIndex">
    <tr title="{{item.userId}}" (dblclick)="openProjectUserDialog(item.userId)" class="cursor-pointer">
      <td>{{item.email}}</td>
      <td>{{item.role}}</td>
      <td>
        <i class="pi" [ngClass]="{'pi-check-circle':item.isActive, 'pi-times-circle':!item.isActive }"></i>
      </td>
      <td>
        <i class="pi" [ngClass]="{'pi-question-circle':item.status == statusEnum.Pending, 'pi-check-circle':item.status == statusEnum.Approved, 'pi-times-circle':item.status == statusEnum.Rejected }"></i>
      </td>
      <td class="text-right">
        <p-button icon="pi pi-pencil" styleClass="p-button-link" (click)="openProjectUserDialog(item.userId)"></p-button>
        <p-button icon="pi pi-times" styleClass="p-button-link" (click)="deleteProjectUser(item.userId)"></p-button>
      </td>
    </tr>
  </ng-template>
</p-table>
<p-confirmDialog></p-confirmDialog>

<!--projectId: string = uuidv4(); // This will be a GUID
userId: string = uuidv4(); // This will be a GUID
status: UserApprovalEnum = UserApprovalEnum.None;
opsApproved: UserApprovalEnum = UserApprovalEnum.None;
ownerApproved: UserApprovalEnum = UserApprovalEnum.None;
creationDateTime: Date = new Date();
mappedBy: string = uuidv4(); // This will be a GUID
isOwner: boolean = false;-->
