import { v4 as uuidv4 } from 'uuid';

export class ProjectProfileDto {
  projectId: string = ''; 
  projectName: string = '';
  tenantId: string = uuidv4(); // This will be a GUID
  tenantName: string = '';

  constructor(partial?: Partial<ProjectProfileDto>) {
    Object.assign(this, partial);
  }
}
 
