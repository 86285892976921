import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { LayoutService } from '../../../../shared/services/layout.service';
import { UserProjectMappingDto, UserProjectMappingStatusEnum } from '../api/user-project-mapping.model';
import { ConfigurationService } from '../../shared/api/configuration.service';
import { UserProjectMappingService } from '../api/user-project-mapping.service';
import { ProjectUserEditComponent } from './project-user-edit.component';

@Component({
  templateUrl: './project-users-list.component.html',
  styles: ['.p-autocomplete-panel {z-index: 1003!important;} :host ::ng-deep .p-tabview .p-tabview-panels {padding:0} .p-dialog .p-dialog-content {padding:0 !important}'],
  providers: [DialogService, DynamicDialogRef, DynamicDialogConfig, ConfigurationService, ConfirmationService]
})
export class ProjectUserListComponent implements OnInit {

  filterEmails: string[] = [];
  filterProjects: string[] = [];
  filterStatuses: string[] = [UserProjectMappingStatusEnum.Pending, UserProjectMappingStatusEnum.Approved, UserProjectMappingStatusEnum.Rejected];

  projectUsers: UserProjectMappingDto[] = [];
  projectUsersFiltered: UserProjectMappingDto[] = [];

  projectUserDialog!: DynamicDialogRef;

  statusEnum = UserProjectMappingStatusEnum;

  filters: UserProjectMappingListFilter = {};

  constructor(
    private dialogService: DialogService,
    private layout: LayoutService,
    private confirmationService: ConfirmationService,
    private userProjectMappingService: UserProjectMappingService,
    private route: ActivatedRoute
  ) {
    //this.projectId = this.config.data.id;
  }

  async ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.filters.projectId = params['projectId'];
      this.filters.userId = params['userId'];
      this.filters.email = params['email'];
      this.filters.status = params['status'];

      this.refreshList();
    });
  }

  refreshFilterList(users: UserProjectMappingDto[]) {
    this.filterEmails = [...new Set(users.map(q => q.email))];
    this.filterProjects = [...new Set(users.map(q => q.projectId))];
  }

  filterList() {
    this.projectUsersFiltered = this.projectUsers
      .filter(p =>
        (!this.filters.projectId || p.projectId === this.filters.projectId)
        && (!this.filters.userId || p.userId === this.filters.userId)
        && (!this.filters.email || p.email === this.filters.email)
        && (!this.filters.status || p.status === this.filters.status)
      );
  }

  filtersClear() {
    this.filters.projectId = undefined;
    this.filters.userId = undefined;
    this.filters.email = undefined;
    this.filters.status = undefined;

    this.refreshList();
  }

  async refreshList() {
    this.layout.startLoading();
    this.userProjectMappingService
      .projectMappings()
      //.projectMappings(this.filters.projectId ?? undefined, this.filters.userId ?? undefined, this.filters.email ?? undefined, this.filters.status ?? undefined)
      .subscribe((response: UserProjectMappingDto[]) => {
        this.refreshFilterList(response);
        this.projectUsers = response;
        this.filterList();
        this.layout.stopLoading();
      });
  }

  deleteProjectUser(projectId: string, userId: string) {
    this.confirmationService.confirm({
      //target: event.target as EventTarget,
      message: 'Do you want to delete this project user?',
      header: 'Delete Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptButtonStyleClass: "p-button-danger p-button-text",
      rejectButtonStyleClass: "p-button-text p-button-text",
      acceptIcon: "none",
      rejectIcon: "none",


      accept: () => {
        this.userProjectMappingService.projectUserDelete(projectId, userId)
          .subscribe(() => {
            this.refreshList();
          });
      },
      reject: () => {
      }
    });
  }

  //async emailAutocomplete(event: any) {
  //  const q = event.query;
  //  this.filterEmails = this.emails.filters(p => {
  //    return p.toLowerCase().indexOf(q) >= 0;
  //  })
  //}  

  openProjectUserDialog(projectId?: string, userId?: string) {
    this.projectUserDialog = this.dialogService
      .open(ProjectUserEditComponent, {
        data: { projectId: projectId, userId: userId },
        header: 'User Project Mapping',
        width: '800px'
      });

    this.projectUserDialog.onClose
      .subscribe(() => {
        this.refreshList();
      });
  }

}

class UserProjectMappingListFilter {
  projectId?: string;
  userId?: string;
  email?: string;
  status?: string;
}
