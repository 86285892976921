@if(form){
<form #formProjectUser [formGroup]="form">
  <div>
    <div class="field">
      <label for="projectId" class="block text-900 font-medium mb-2">Project *</label>
      <p-dropdown [options]="projects" formControlName="projectId" placeholder="Project"
                  [editable]="false" appendTo="body" styleClass="w-full mb-3" inputStyleClass="w-full"></p-dropdown>
    </div>
    <div class="field">
      <label for="email" class="block text-900 font-medium mb-2">Email *</label>
      <!--<input id="email" formControlName="email" type="email" pInputText placeholder="Email (Start typing for search)" class="w-full mb-3">-->

      <p-autoComplete styleClass="w-full mb-3" inputStyleClass="w-full" formControlName="email"
                      [suggestions]="usersAutocomplete" appendTo="body" field="email" [minLength]=3 dataKey="userId"
                      (completeMethod)="userAutocomplete($event)" (onSelect)="userSelected($event)"></p-autoComplete>
      <!--[(ngModel)]="userToAdd"-->
    </div>
    <div class="field">
      <label for="meshUsername" class="block text-900 font-medium mb-2">Mesh UserName *</label>
      <input id="meshUsername" formControlName="meshUsername" type="text" pInputText placeholder="Mesh User Name" class="w-full mb-3">
    </div>

    <div class="field">
      <label for="role" class="block text-900 font-medium mb-2">Role *</label>
      <p-dropdown [options]="roles" formControlName="role" placeholder="Role"
                  [editable]="false" appendTo="body" styleClass="w-full mb-3" inputStyleClass="w-full"></p-dropdown>
    </div>

    <div class="field">
      <label for="status" class="block text-900 font-medium mb-2">Status *</label>
      <p-dropdown [options]="statuses" formControlName="status" placeholder="Status"
                  [editable]="false" appendTo="body" styleClass="w-full mb-3" inputStyleClass="w-full"></p-dropdown>
    </div>

    <div class="grid">
      <div class="field-checkbox col-3">
        <p-checkbox formControlName="isActive" [binary]="true" inputId="isActive"></p-checkbox>
        <label class="ml-2" for="isActive">Is Active</label>
      </div>
      <div class="field col-6">
        <label for="userId" class="block text-900 font-medium mb-2">User Id</label>
        <span id="userId" class="w-full mb-3">{{form.get('userId')?.value}}</span>
      </div>
      <div class="field col-3">
        <label for="creationDateTime" class="block text-900 font-medium mb-2">Creation Date</label>
        <span class="w-full mb-3">{{form.get('creationDateTime')?.value |date:'mediumDate'}}</span>
      </div>
    </div>


    <div class="text-center">
      <p-button label="Cancel" icon="pi pi-times" class="m-3" (click)="cancel()"></p-button>
      <p-button label="Save" icon="pi pi-check" class="m-3" [disabled]="!form.valid" (onClick)="save()"></p-button>
    </div>

  </div>
</form>
<p-blockUI [target]="formProjectUser" [blocked]="formBlocked">
  <i class="pi pi-cloud-upload" style="font-size: 3rem"></i>
</p-blockUI>

}
@else{
  <div class="flex justify-content-center">
    <p-progressSpinner></p-progressSpinner>
  </div>
}
