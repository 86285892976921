import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { TableModule } from 'primeng/table';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { TabViewModule } from 'primeng/tabview';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { DropdownModule } from 'primeng/dropdown';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CheckboxModule } from 'primeng/checkbox';
import { ToastModule } from 'primeng/toast';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { BlockUIModule } from 'primeng/blockui';
import { ProgressSpinnerModule } from 'primeng/progressspinner';

import { ProjectListComponent } from './components/project-list.component';
import { ProjectEditComponent } from './components/project-edit.component';
import { ProjectUsersComponent } from './components/project-users.component';
import { ProjectUserEditComponent } from './components/project-user-edit.component';
import { ProjectUserListComponent } from './components/project-users-list.component';
import { ProjectsRoutingModule } from './projects-routing.module';
import { ControlErrorModule } from '../../../shared/directives/control-error.module';


@NgModule({
  declarations: [
    ProjectListComponent,
    ProjectEditComponent,
    ProjectUsersComponent,
    ProjectUserEditComponent,
    ProjectUserListComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ConfirmDialogModule,
    ReactiveFormsModule,
    AutoCompleteModule,
    CheckboxModule,
    ButtonModule,
    DropdownModule,
    TableModule,
    ToastModule,
    InputTextModule,
    TabViewModule,
    DynamicDialogModule,
    BlockUIModule,
    ControlErrorModule,
    ProgressSpinnerModule,

    ProjectsRoutingModule
  ]
})
export class ProjectsModule {
}
